/**
 * see: https://github.com/microsoft/TypeScript/issues/47920 and https://stackoverflow.com/questions/70956050
 *
 * use: `const dateMap = satisfies<Record<string, Date>>()({ "hello": new Date() });`.
 *
 * Allows type checking of const without widening of type.  Pass boundary type as generic, call without arguments, then call return type with the object you want to type-check as first argument.
 * @returns Function to pass value into which, in turn, returns exact same object.
 */
export const satisfies =
  <T>() =>
  <U extends T>(u: U) =>
    u;

/**
 * see: https://stackoverflow.com/questions/60131681/make-sure-array-has-all-types-from-a-union
 * 
 * use: `const dateMap = arrayOfAll<"a" | "b">()(["a", "b"]);`.
 * 
 * Typechecks an array to make sure it has all values from a union.
 * @returns Function to pass array into which, in turn, returns exact same array.
 */
export const arrayOfAll =
  <T>() =>
  <U extends T[]>(array: U & ([T] extends [U[number]] ? unknown : "Invalid")) =>
    array;
